export const CommercialPartnerRegion = {
  ALL: 'all',
  ILE_DE_FRANCE: 0,
  AUVERGNE_RHONE_ALPES: 1,
  BOURGOGNE_FRANCHE_COMPTE: 2,
  BRETAGNE: 3,
  CENTRE_VALE_DE_LOIRE: 4,
  GRAND_EST: 5,
  HAUTS_DE_FRANCE: 6,
  NORMANDIE: 7,
  NOUVELLE_AQUITAINE: 8,
  OCCITANIE: 9,
  PAYS_DE_LA_LOIRE: 10,
  PROVENCE_ALPES_COTE_AZURE_ET_CORSE: 11
};

export const CommercialPartnerCategory = {
  ALL: 'all',
  VENUES: 1,
  CATERERS: 2,
  OUTFITS_ACCESSORIES: 3,
  MARRIAGE_DATE_PARTNERS: 4,
  PHOTOGRAPHERS_VIDEOGRAPHERS: 5,
  GUESTS: 6,
  HONEYMOON: 7,
  OTHER: 0
};

export const CommercialPartnerCategoryFr = {
  ALL: 'all',
  LIEUX_DE_RECEPTION: 1,
  TRAITEURS: 2,
  TENUES_DE_MARIAGE_ACCESSOIRES: 3,
  PARTENAIRES_DU_JOUR_J: 4,
  PHOTOGRAPHES_VIDEASTES: 5,
  POUR_LES_INVITES: 6,
  VOYAGE_DE_NOCES: 7,
  WEDDING_PLANNER: 0
};


export const CommercialPartnerStatus = {
  INACTIVE: 0,
  ACTIVE: 1
};



export const regionMap = Object.entries(CommercialPartnerRegion)
.reduce((acc, [key, val]) => {
  // Convert the key to lowercase with hyphens
  const label = key.toLowerCase().replace(/_/g, '-');
  acc[val] = label;
  return acc;
}, {});

export const reverseRegionMap = Object.entries(CommercialPartnerRegion)
.reduce((acc, [key, val]) => {
  const normalized = key.toLowerCase().replace(/_/g, '-');
  acc[normalized] = val;
  return acc;
}, {});

export const categoryMap = Object.entries(CommercialPartnerCategoryFr)
.reduce((acc, [key, val]) => {
  const label = key.toLowerCase().replace(/_/g, '-');
  acc[val] = label;
  return acc;
}, {});

export const reverseCategoryMap = Object.entries(CommercialPartnerCategoryFr)
.reduce((acc, [key, val]) => {
  const normalized = key.toLowerCase().replace(/_/g, '-');
  acc[normalized] = val;
  return acc;
}, {});
